<template>
  <x-data-template
    class="x-data-list"
    v-bind="$props"
    :paging="computedPaging"
    :items="computedItems"
    @input="onUpdatePaging"
  >
    <template #header>
      <div
        class="x-data-list__header"
        :style="{ gridTemplateColumns }"
      >
        <x-data-header-cell
          v-for="(col, i) of columns"
          :key="i"
          :col="col"
          :sort="computedSort"
          @click="sortCol(col)"
        >
          <slot
            :name="`header-cell.${col.prop}`"
            :col="col"
          />
        </x-data-header-cell>
      </div>
    </template>

    <template #content>
      <slot name="content" />
    </template>

    <x-card
      color="gray"
      size="xs"
    >
      <x-card
        v-for="(item, i) in computedItems"
        :key="getItemKey(item, i)"
        v-bind="rowProps(item)"
        class="x-data-list__row"
        size="xs"
        :metrics="metrics"
      >
        <div
          class="x-data-list__cells-wrapper"
          :style="{ gridTemplateColumns }"
        >
          <div
            v-for="(col, j) of columns"
            :key="j"
            :class="cellClasses(col, item)"
          >
            <slot
              :name="`cell.${col.prop}`"
              :item="item"
            >
              {{ defaultFormatter(item, col) }}
            </slot>
          </div>
        </div>
        <slot name="row.bottom" :item="item"></slot>
      </x-card>
    </x-card>
  </x-data-template>
</template>

<script>
import { propsMixin, methodsMixin, pagingMixin } from './XData'
import XDataTemplate from './XDataTemplate.vue'
import XDataHeaderCell from './XDataHeaderCell.vue'

export default {
  components: {
    XDataTemplate,
    XDataHeaderCell
  },
  mixins: [
    propsMixin,
    methodsMixin,
    pagingMixin
  ],
  props: {
    to: Function,
    metrics: String
  },
  methods: {
    rowProps (item) {
      if (this.to) {
        return { to: this.to(item) }
      }
    }
  }
}
</script>

<style lang="stylus">
@import 'x-data.styl'

$border-width = 1px

.x-data-list
  &__header
    background-color #fff
    padding 0 $space * 6
    border-top: $border-width solid $colors.gray-light
    border-bottom: $border-width solid $colors.gray-light
    @extend $header

  &__row
    & + &
      margin-top $space * 4

  &__cells-wrapper
    display grid
    margin 0 $space * (-2)

  .x-data__cell
    flex-grow 1
</style>
