import i18n from '@/plugins/vue-i18n'
import dayjs from 'dayjs'
// non-strict comparison assumes the value is equal to 'null' or 'undefined'

export function formatCount (val?: number | string) {
  return val == null ? '—' : i18n.n(+val)
}

export function formatCurrency (val?: number | string) {
  return val == null ? '—' : i18n.n(+val, 'currency')
}

export function formatCurrencyWithSymbol (
  val?: number | string,
  currency = 'RUB'
) {
  return val == null
    ? '—'
    : i18n.n(+val, {
      style: 'currency',
      currency,
      currencyDisplay: 'symbol'
    })
}

export function formatPercent (val?: number | string) {
  return val == null ? '—' : `${i18n.n(+val)}%`
}

export function formatEmpty (val?: number | string) {
  return val ?? '—'
}

export function addSign (val: number) {
  return val > 0 ? `+${val}` : `−${Math.abs(val)}`
}

export function formatDateTime (ISODate: string, format: string) {
  return dayjs(ISODate).format(format)
}
