export default {
  ali: {
    id: 1,
    code: 'ali',
    name: 'AliExpress',
    icon: 'aliexpress'
  },
  ozon: {
    id: 2,
    code: 'ozon',
    name: 'Ozon',
    icon: 'ozon'
  },
  beru: {
    id: 4,
    code: 'beru',
    name: 'Яндекс.Маркет',
    icon: 'yandex-market'
  },
  goods: {
    id: 5,
    code: 'goods',
    name: 'СберМегаМаркет',
    icon: 'sber-mega-market'
  },
  wildberries: {
    id: 9,
    code: 'wildberries',
    name: 'Wildberries',
    icon: 'wildberries'
  }
} as const
