<template>
  <div
    class="x-data"
    :class="classes"
  >
    <slot name="above-header" />
    <div class="x-data__wrapper">
      <div
        class="x-data__sticky-header"
        :class="{ 'x-data__sticky-header--at-top': $layout.scrollingDown || $layout.scrolledToBottom }"
      >
        <div
          v-if="scrollable"
          ref="header"
          class="x-data__header-wrapper"
          @scroll="onHeaderScroll"
        >
          <slot name="header"></slot>
        </div>
        <slot
          v-else
          name="header"
        >
        </slot>
        <x-progress
          indeterminate
          class="x-data__progress"
          :class="{'x-data__progress--active': loading }"
        />
      </div>

      <div
        v-if="$slots.content"
        class="x-data__content"
      >
        <slot name="content" />
      </div>
      <x-placehandler
        v-else
        :noData="items && !items.length"
        :error="error"
      >
        <div
          v-if="items"
          class="x-data__loading-mask"
          :class="{ 'x-data__loading-mask--active': loading }"
        >
          <div
            v-if="scrollable"
            ref="body"
            class="x-data__body"
            @scroll="onBodyScroll"
          >
            <!-- need inner wrapper for sticky columns (otherwise scrolling doesn't work correctly) -->
            <div class="x-data__body-inner">
              <slot></slot>
            </div>
          </div>
          <slot v-else></slot>
        </div>
      </x-placehandler>

      <template v-if="scrollable">
        <div
          class="x-data__left-shadow"
          :class="{ 'x-data__left-shadow--shown': shadowLeft }"
        />
        <div
          class="x-data__right-shadow"
          :class="{ 'x-data__right-shadow--shown': shadowRight }"
        />
      </template>
    </div>

    <x-paginator
      v-if="!noPaging"
      class="x-data__paginator"
      :options="paging"
      @input="onInputPaging"
    />
  </div>
</template>

<script>
import { propsMixin } from './XData'
import XProgress from '@/components/ui/XProgress'
export default {
  inheritAttrs: false,
  components: { XProgress },
  mixins: [
    propsMixin
  ],
  props: {
    scrollable: Boolean,
    fixedLeftColumns: Array,
    fixedRightColumns: Array
  },
  data: () => ({
    columnShadowLeft: false,
    columnShadowRight: false,
    shadowLeft: false,
    shadowRight: false
  }),
  computed: {
    classes () {
      return this.scrollable && {
        'x-data--column-shadow-left': this.columnShadowLeft,
        'x-data--column-shadow-right': this.columnShadowRight
      }
    }
  },
  mounted () {
    if (this.scrollable) {
      // Add initial shadows
      this.$watch('items', () => {
        this.setShadows(this.$refs.header)
      }, {
        immediate: true
      })
    }
  },
  methods: {
    onInputPaging (e) {
      this.$emit('input', e)
    },
    allowOtherScroll () {
      const ignore = this.ignoreScrollEvents
      this.ignoreScrollEvents = false

      if (ignore) {
        return false
      }

      this.ignoreScrollEvents = true
      return true
    },
    setShadows ({ scrollWidth, clientWidth, scrollLeft }) {
      const hasScroll = scrollWidth > clientWidth

      if (hasScroll) {
        const scrollNotAtTheEnd = scrollLeft + clientWidth < scrollWidth
        const scrollNotAtTheStart = scrollLeft > 0

        const hasFixedLeft = this.fixedLeftColumns.length > 0
        const hasFixedRight = this.fixedRightColumns.length > 0

        this.shadowLeft = !hasFixedLeft && scrollNotAtTheStart
        this.shadowRight = !hasFixedRight && scrollNotAtTheEnd

        this.columnShadowRight = hasFixedLeft && scrollNotAtTheStart
        this.columnShadowLeft = hasFixedRight && scrollNotAtTheEnd
      } else {
        this.shadowLeft = false
        this.shadowRight = false
        this.columnShadowRight = false
        this.columnShadowLeft = false
      }
    },
    onHeaderScroll (e) {
      if (this.allowOtherScroll()) {
        requestAnimationFrame(() => {
          const body = this.$refs.body

          if (body) {
            body.scrollLeft = e.target.scrollLeft
            this.setShadows(e.target)
          }
        })
      }
    },
    onBodyScroll (e) {
      if (this.allowOtherScroll()) {
        requestAnimationFrame(() => {
          this.$refs.header.scrollLeft = e.target.scrollLeft
          this.setShadows(e.target)
        })
      }
    }
  }
}
</script>

<style lang="stylus">
@import 'x-data.styl'

.x-data
  font-family $font-family
  font-size: $font-sizes.text
  min-height 308px

  &__wrapper
    position relative
    display flex
    flex-direction column
    flex-grow 1

  &__sticky-header
    position sticky
    top: $layout-sizes.header-height - $border-width
    z-index: $z-indexes.data-header
    transition ease-out top .25s

    &--at-top
      top $border-width * (-1)

  &__header-wrapper
    display flex
    overflow auto
    will-change scroll-position
    background-color #fff
    color: $colors.gray-darker
    border-top: $border-width solid $colors.gray-light
    border-bottom: $border-width solid $colors.gray-light
    scrollbar-width none
    -ms-overflow-style none

    &::-webkit-scrollbar
      display none

  &__content
    margin-top $space * 8
    margin-bottom $space * 8

  &__body
    position relative
    display flex
    flex-wrap wrap
    overflow auto
    padding-bottom $scroll-padding
    will-change scroll-position

    &-inner
      flex-grow 1
      flex-shrink 1

  &__left-shadow, &__right-shadow
    position absolute
    top 0
    bottom 0
    z-index 2
    height 'calc(100% - %s)' % $scroll-padding
    width 0px
    transition $shadow-transition

  &__left-shadow
    left 0
    clip-path $shadow-left-clip

    &--shown
      box-shadow $shadow

  &__right-shadow
    right 0
    clip-path $shadow-right-clip

    &--shown
      box-shadow $shadow

  &__progress
    margin-bottom $space * (-2)
    opacity 0.5
    transform-origin top
    transform scaleY(0)
    transition transform .25s cubic-bezier(.13,1.09,0,1) .1s, opacity .25s ease .1s

    &--active
      opacity 1
      transform scaleY(1)

  &__loading-mask
    opacity 1
    transition opacity .25s ease
    &--active
      opacity .5
      pointer-events none

  &__cell
    background-color inherit
    overflow auto
    padding $space * 2

    &--align-end
      text-align right

    &--align-center
      text-align center

    &--fixed
      position sticky
      z-index 1
      transition $shadow-transition

    &--shadow-left
      clip-path $shadow-left-clip

    &--shadow-right
      clip-path $shadow-right-clip

  &__paginator
    margin-top $space * 5

.x-data--column-shadow-right .x-data__cell--shadow-left,
.x-data--column-shadow-left .x-data__cell--shadow-right
  box-shadow $shadow

</style>
