/* eslint-disable @typescript-eslint/naming-convention */

import { formatEmpty } from '@/utils/formatters'
import { mainSeriesColors } from 'xway-ui/utils/colors'
import { sortSeriesColors } from '@/utils/sortSeriesColors'

const colors = Object.keys(mainSeriesColors)

export const basePropsMixin = {
  props: {
    items: {
      type: Array
    },
    loading: Boolean,
    error: [Boolean, Error],
    noDataText: String
  }
}

export const pagingMixin = {
  props: {
    remote: {
      type: Boolean,
      default: true
    },
    search: String,
    customFilter: Function,
    customSort: Function
  },
  data: () => ({
    defaultPaging: {
      page: 1,
      limit: 10
    },
    filterPaging: {
      page: 1
    },
    defaultSort: {
      direction: 'DESC'
    }
  }),
  computed: {
    computedItems () {
      if (this.remote) {
        return this.items
      }

      if (this.noPaging) {
        return this.filteredItems
      }

      const start = (this.computedPaging.page - 1) * this.computedPaging.limit
      const end = start + this.computedPaging.limit

      return this.filteredItems?.slice(start, end)
    },
    computedDefaultPaging () {
      const paging = {
        ...this.defaultPaging,
        ...this.paging
      }

      const total = this.filteredItems?.length || 0
      const maxPage = Math.ceil(total / paging.limit)
      const page = paging.page > maxPage ? maxPage : paging.page

      return {
        ...paging,
        page,
        total
      }
    },
    computedPaging () {
      if (this.remote) {
        return this.paging
      }

      if (this.filterApplied) {
        return {
          ...this.computedDefaultPaging,
          ...this.filterPaging
        }
      }

      return this.computedDefaultPaging
    },
    hasSortableColumns () {
      return this.columns.some(el => el.sortable)
    },
    computedSort () {
      if (!this.hasSortableColumns) {
        return null
      }

      if (this.remote) {
        return this.sort
      }

      const sort = {
        ...this.defaultSort,
        ...this.sort
      }

      if (!sort.field) {
        const sortableCol = this.columns.find(el => el.sortable)
        sort.field = sortableCol.prop
      }

      return sort
    },
    filterApplied () {
      return this.search != null && this.search.trim() !== ''
    },
    filteredItems () {
      if (this.filterApplied) {
        const search = this.search.trim()
        // TODO: improve default filter
        const defaultFilter = (search, el) => el === search.toLowerCase()
        const filterItem = this.customFilter || defaultFilter

        return this.sortedItems.filter((el, i) => filterItem(search, el, i))
      }
      return this.sortedItems
    },
    sortedItems () {
      // TODO: improve default sort
      const defaultSort = (a, b) => a - b
      const sortItems = this.customSort || defaultSort

      return this.hasSortableColumns
        ? sortItems(this.items, this.computedSort)
        : this.items
    }
  },
  watch: {
    filteredItems (filteredItems) {
      if (!filteredItems.length) {
        this.filterPaging.page = 1
      }
    }
  },
  methods: {
    sortCol (col) {
      if (!col.sortable) return

      const isColumnChanged = col.prop !== this.computedSort.field
      const isColumnAscending = this.computedSort.direction === 'ASC'

      const newSort = {
        field: col.prop,
        direction: (isColumnChanged || isColumnAscending) ? 'DESC' : 'ASC'
      }

      this.defaultSort = newSort

      if (this.sort) {
        this.$emit('update:sort', newSort)
      }
    },
    onUpdatePaging (e) {
      if (this.filterApplied) {
        this.filterPaging = e
        this.defaultPaging.limit = e.limit
      } else {
        this.defaultPaging = e
        if (this.paging) {
          this.$emit('update:paging', e)
        }
      }
    },
    getItemKey (item, index) {
      return this.itemKey ? this.itemKey(item) : index
    }
  }
}

export const propsMixin = {
  props: {
    columns: {
      type: Array,
      default: () => [],
      validator: v => v.every(el => el.minWidth > 0)
    },
    items: {
      type: Array
    },
    itemKey: {
      type: Function,
      default: item => item.id
    },
    paging: {
      type: Object
    },
    sort: {
      type: Object,
      validator: v => !v || (v.field && ['DESC', 'ASC'].includes(v.direction))
    },
    loading: Boolean,
    error: [Boolean, Error],
    noPaging: Boolean,
    noDataText: String
  }
}

export const gridMixin = {
  props: {
    rowClasses: {
      type: [Function, Array, Object, String]
    }
  },
  computed: {
    fixedLeftColumns () {
      const columns = this.showSelect ? [this.checkboxCol, ...this.columns] : this.columns
      return columns.filter(el => el.fixedLeft)
    },
    fixedRightColumns () {
      return this.columns.filter(el => el.fixedRight)
    }
  },
  methods: {
    fixedCellStyles (col, index) {
      if (col.fixedLeft) {
        if (col === this.checkboxCol) {
          return {
            left: '0px'
          }
        }

        let left =
          this.columns
            .slice(0, index)
            .filter(el => el.fixedLeft)
            .reduce((acc, el) => acc + el.minWidth, 0)

        if (this.showSelect) {
          left += this.checkboxCol.minWidth
        }

        return {
          left: left + 'px'
        }
      }
      if (col.fixedRight) {
        const right =
          this.columns
            .slice(index + 1)
            .filter(el => el.fixedRight)
            .reduce((acc, el) => acc + el.minWidth, 0)

        return {
          right: right + 'px'
        }
      }
      return null
    },
    fixedCellClasses (col) {
      return {
        'x-data__cell--fixed': col.fixedLeft || col.fixedRight,
        'x-data__cell--shadow-left': this.fixedLeftColumns[this.fixedLeftColumns.length - 1] === col,
        'x-data__cell--shadow-right': this.fixedRightColumns[0] === col
      }
    },
    getRowClasses (item) {
      return typeof this.rowClasses === 'function' ? this.rowClasses(item) : this.rowClasses
    }
  }
}

export const methodsMixin = {
  computed: {
    gridTemplateColumns () {
      const columns = this.showSelect ? [this.checkboxCol, ...this.columns] : this.columns
      const total = columns.reduce((acc, el) => acc + el.minWidth, 0)

      return columns.map(el => `minmax(${el.minWidth}px, ${el.minWidth / total * 100}%)`).join(' ')
    }
  },
  methods: {
    cellClasses (col, item) {
      const cellClasses = typeof col.cellClasses === 'function'
        ? col.cellClasses(item, col)
        : col.cellClasses

      return [
        'x-data__cell',
        col.align && `x-data__cell--align-${col.align}`,
        cellClasses
      ]
    },
    defaultFormatter (item, col) {
      return col.formatter ? (col.formatter(item, col) ?? '—') : formatEmpty(item[col.prop])
    }
  }
}

export const selectableMixin = {
  props: {
    showSelect: Boolean,
    fixedSelect: Boolean,
    coloredCheckboxes: Boolean,
    selected: {
      type: Array,
      default: () => []
    },
    primaryItemMatcher: Function
  },
  computed: {
    checkboxCol () {
      return {
        prop: 'checkbox-col',
        sortable: false,
        minWidth: 32,
        align: 'center',
        fixedLeft: this.columns[0].fixedLeft || this.fixedSelect
      }
    },
    checkboxDisabled () {
      // TODO: ???
      return this.selected.length > colors.length
    },
    mainCheckboxValue () {
      return this.selected.length > 0
    },
    mainCheckboxIndeterminate () {
      return this.selected.length !== (this.computedPaging?.total ?? this.items.length)
    }
  },
  watch: {
    selected: 'updateCheckboxesColors'
  },
  created () {
    // non-reactive
    this.itemsColorMap = new Map()
    this.availableColors = colors.slice()

    // call here, because watch handler call earlier
    this.updateCheckboxesColors()
  },
  methods: {
    updateCheckboxesColors () {
      if (!this.coloredCheckboxes) return

      const val = this.selected

      if (!val.length) {
        this.availableColors = colors.slice()
        this.itemsColorMap = new Map()
        return
      }

      const previousKeys = [...this.itemsColorMap.keys()]
      const currentKeys = val.map(this.itemKey)

      const unusedKeys = previousKeys.filter(key => !currentKeys.includes(key))
      const freshKeys = currentKeys.filter(key => !previousKeys.includes(key))

      unusedKeys.forEach(key => {
        const color = this.itemsColorMap.get(key)
        const isPrimary = color === 'primary'
        if (!isPrimary) {
          this.availableColors.unshift(color)
        }
        this.itemsColorMap.delete(key)
      })

      this.availableColors = sortSeriesColors(this.availableColors)

      freshKeys.forEach(key => {
        const isPrimary = this.isPrimaryByKey(key)
        const color = isPrimary ? 'primary' : this.availableColors.shift()
        this.itemsColorMap.set(key, color)
      })
    },
    isPrimaryByKey (key) {
      if (this.primaryItemMatcher) {
        const item = this.items.find(el => this.itemKey(el) === key)
        return !!item && this.primaryItemMatcher(item)
      }

      return false
    },
    toggleAllCheckboxes () {
      const e = !this.selected.length
        ? this.items
        : []
      this.$emit('update:selected', e)
    },
    toggleCheckbox (e) {
      this.$emit('update:selected', e)
    },
    checkboxCellClasses (item) {
      const key = this.itemKey(item)
      const color = this.itemsColorMap.get(key)

      return color && `x-data__checkbox-cell--${color}`
    }
  }
}
