<template>
  <div
    class="x-progress"
  >
    <div
      class="x-progress__inner"
      :style="styles"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
      default: 0
    },
    bufferValue: {
      type: [Number, String],
      default: 100
    },
    indeterminate: Boolean
  },
  computed: {
    styles () {
      return {
        width: `${this.indeterminate ? 100 : this.value / this.bufferValue * 100}%`
      }
    }
  }
}
</script>

<style lang="stylus">
loading-image(c1, c2)
  background-image linear-gradient(-45deg, c1 25%, c2 25%, c2 50%, c1 50%, c1 75%, c2 75%, c2)

.x-progress
  height 8px
  background-color: $colors.primary-lightest-1

  &__inner
    width 0%
    height 100%
    background-color: $colors.primary
    background-size 16px 16px
    animation progress-loading .5s ease infinite
    loading-image(transparent, $colors.white-lightest-2)
    transition width .5s ease-in-out
    will-change width

@keyframes progress-loading
  0%
    background-position 0 0

  100%
    background-position 16px 16px
</style>
