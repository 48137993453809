import i18n from '@/plugins/vue-i18n'

export default [
  {
    id: 'new',
    name: 'Новая'
  },
  {
    id: 'on_customs',
    name: 'на томожне'
  },
  {
    id: 'done',
    name: 'завершена'
  }
]

/*
Новая
На рассмотрении
Требует уточнений
Согласована
Доставляется в РФ
Растоможена
На складах в РФ
В продаже
Завершена

Under consideration
Requires clarification
Agreed
Delivered to Russia
Customs cleared
In warehouses in the Russian Federation
On sale
Completed */
