<template>
  <div
    class="x-data-header-cell"
    :class="colClasses"
    v-on="$listeners"
  >
    <slot>
      <div v-html="col.name" />
    </slot>
    <x-icon
      v-if="col.sortable"
      name="arrow-down"
      size="xs"
      class="x-data-header-cell__sort-icon"
    />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    col: {
      type: Object,
      default: () => ({})
    },
    sort: {
      type: Object,
      validator: v => !v || (v.field && ['DESC', 'ASC'].includes(v.direction))
    }
  },
  computed: {
    colClasses () {
      const { col, sort } = this
      const res = []

      if (col.align) {
        res.push(`x-data-header-cell--align-${col.align}`)
      }

      if (col.sortable && sort) {
        res.push('x-data-header-cell--sortable')

        if (col.prop === sort.field) {
          res.push('x-data-header-cell--sorted')

          if (sort.direction === 'ASC') {
            res.push('x-data-header-cell--ascending')
          }
        }
      }

      return res
    }
  }
}
</script>

<style lang="stylus">
  .x-data-header-cell
    background-color #fff
    display flex
    flex-shrink 0
    flex-grow 1
    flex-basis 0px
    min-height 34px
    font-family $font-family
    font-size: $font-sizes.text
    font-weight: $font-weights.normal
    color: $colors.gray-darker
    padding $space * 2

    &--align-end
      justify-content flex-end
      text-align right
      .x-data-header-cell__sort-icon
        order -1
        margin-right ($space / 2)

    &--align-center
      justify-content center
      text-align center

    &--sortable
      user-select none
      cursor pointer
      transition color .3s cubic-bezier(0.215, 0.61, 0.355, 1), background-color .3s cubic-bezier(0.215, 0.61, 0.355, 1)

      .x-data-header-cell__sort-icon
        flex-shrink 0
        opacity 0
        transform-origin center
        transition transform .25s ease-out

      &:hover
        background-color: $colors.gray-lightest-2
        color: $colors.gray-darkest-2
        .x-data-header-cell__sort-icon
          opacity 1
          color: $colors.gray-darkest-1

      &:active
        transition none
        background-color: $colors.gray-lightest-1

    &--sortable&--sorted
      color: $colors.gray-darkest-2
      font-weight: $font-weights.semibold

      .x-data-header-cell__sort-icon
        opacity 1
        color: $colors.gray-darkest-2

    &--sortable&--sorted&--ascending
      .x-data-header-cell__sort-icon
        transform rotate(180deg)
</style>
