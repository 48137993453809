<template>
  <label class="x-switcher">
    <span class="x-switcher__in">
      <input
        class="x-switcher__input visually-hidden"
        :class="{'x-switcher__input_rounded': rounded}"
        type="checkbox"
        :checked="isChecked"
        :value="value"
        @change="updateInput"
      >
      <span
        :class="['x-switcher__pseudo', {'x-switcher__pseudo_rounded': rounded}]"
      />
      <span class="x-switcher__text">
        <slot />
      </span>
    </span>
  </label>
</template>

<script>
export default {
  model: {
    prop: 'modelValue',
    event: 'change'
  },

  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    modelValue: {
      type: [Boolean, Array],
      default: false
    },
    trueValue: {
      type: Boolean,
      default: true
    },
    falseValue: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isChecked () {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value)
      }

      return this.modelValue === this.trueValue
    }
  },

  methods: {
    updateInput (e) {
      const target = e.target
      const isChecked = target.checked

      if (this.modelValue instanceof Array) {
        const newValue = [...this.modelValue]

        if (isChecked) {
          newValue.push(this.value)
        } else {
          newValue.splice(newValue.indexOf(this.value), 1)
        }

        this.$emit('change', newValue)
      } else {
        this.$emit('change', isChecked ? this.trueValue : this.falseValue)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .x-switcher
    display inline-block
    line-height 16px
    text-align left
    white-space nowrap
    cursor pointer
    -webkit-tap-highlight-color transparent

    &:hover
      .x-switcher__pseudo
        border-color #3e66fb

    &__in
      position relative
      display inline-block
      line-height 1

    &__input
      &:checked
        ~ .x-switcher__pseudo
          background-color #5b40ff
          border-color #5b40ff

          &:after
            opacity 1

        ~ .x-switcher__text
          color #5b40ff

      &_rounded
        &:checked
          ~ .x-switcher__pseudo_rounded
            &:after
              transform translate(-10%, -45%)

    &__pseudo
      position relative
      display inline-block
      vertical-align middle
      width 16px
      height 16px
      background-color transparent
      border 1px solid #cacad3
      border-radius 3px
      box-sizing border-box
      transition background-color ease .3s, border-color ease .3s
      backface-visibility hidden

      &:after
        content ""
        position absolute
        top 50%
        left 50%
        width 7px
        height 3px
        margin-top -1px
        border-left 2px solid #fff
        border-bottom 2px solid #fff
        transform translate(-50%, -50%) rotate(-45deg)
        opacity 0
        transition opacity linear .1s

      &_rounded
        width 30px
        height 18px
        background-color #cacad3
        border-radius 10px

        &:after
          width 14px
          height 14px
          background-color #fff
          border-radius 25px
          opacity 1
          transform translate(-80%, -45%)
          transition transform linear .2s

    &__text
      display inline-block
      vertical-align middle
      padding-left 4px
      font-size 14px
      line-height 16px
      color #54555f
      white-space normal
      user-select none
</style>
