<template>
  <div class="d-flex align-center">
    <span>{{ $t('Sales on')}}:</span>
    <div class="d-flex">
      <x-icon
        :class="{'x-icon--turned-off': isNotSalesOn(mp.id) }"
        class="x-icon ml-2"
        v-for="mp in marketplaces"
        :key="mp.id"
        :name="mp.icon"
      />
    </div>
  </div>
</template>

<script>
import marketplaces from '@/dictionaries/marketplaces'

export default {
  name: 'SalesOn',

  data: () => ({
    marketplaces
  }),

  props: {
    salesOn: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    isNotSalesOn (id) {
      return !this.salesOn.includes(id)
    }
  }
}
</script>

<style lang="stylus" scoped>
.x-icon
  &--turned-off
    filter grayscale(100%)
    opacity 0.3
</style>
